import React, {Component} from "react";
import '../App.css';

export default class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            isLoading: true,
        };
    }

    render() {
        return (
            <>
                <div className={'App-title'}>
                    <p className={'App-title-text'}>Yhteystiedot</p>
                    <button className="App-title-button" onClick={(e) => this.props.goToContactForm(e)}>Ota yhteyttä</button>
                </div>
                <div className={'App-box'}>
                    <h2>Yhteydenotot mieluusti sähköpostilla</h2>
                    <br/>
                    <h2>Sähköposti</h2>
                    <a className={'contact_details'} href={'mailto:info@saukonmaja.fi'}>info(a)saukonmaja.fi</a>
                    <h2>Puhelinnumero</h2>
                    <a className={'contact_details'} href={'tel:0407408471'}>0407408471</a>
                    <h2>Osoite</h2>
                    <a className={'contact_details'} rel={'noreferrer'} href={'https://goo.gl/maps/rSNGUBL54j3rZeJU6'} target={'_blank'}>Saukkotie 9B, 93830, Rukatunturi</a>
                    <h2>Kartta</h2>
                    <iframe
                        title={'Kartta'} className={'kartta'}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1612.8344449701322!2d29.124827899999996!3d66.1566421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x442954c7434bdeb9%3A0xd30a7c4e4ea777df!2sSaukkotie%209b%2C%2093825%20Kuusamo!5e0!3m2!1sen!2sfi!4v1657813835223!5m2!1sen!2sfi"
                        width="600" height="450" allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>
            </>
        );
    }
}
