const API_KEY = 'hFSEXLREDD4eNCqFSvov58CQE6snDZEH3FBnYwNq'

async function awsPost(data) {
    let result
    try {
        result = await fetch(
            'https://dxzf18nhr1.execute-api.eu-central-1.amazonaws.com/prod/contact',
            {
                method: 'POST',
                headers: {
                    'x-api-key': API_KEY,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )
    } catch (e) {
        console.log(e)
    }
    return await result.json()
}

export async function contactFormPost(data) {
    return await awsPost(data)
}