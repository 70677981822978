import React from "react";
import './App.css';
import Home from "./containers/Home";

function App () {
    return (
        <div className="app">
            <Home />
        </div>
    );
}

export default App;
