import React, {Component} from "react";
import '../App.css';

export default class Gallery extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            isLoading: true,
        };
    }

    renderGallery() {

    }

    render() {
        return (
            <>
                <div className={'App-title'}>
                    <p className={'App-title-text'}>Galleria</p>
                </div>
                <div className={'App-box'}>
                    {this.renderGallery()}
                </div>
            </>
        );
    }
}
