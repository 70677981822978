import React, {Component} from "react";
import '../App.css';
import {contactFormPost} from "../server";

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            isLoading: true,
            email_address: '',
            phone_number: '',
            message: '',
        };
    }

    updateEmail(email) {
        this.setState({
            email_address: email
        })
    }

    updatePhone(phone) {
        this.setState({
            phone_number: phone
        })
    }

    updateMessage(message) {
        this.setState({
            message: message
        })
    }

    async sendMessage(e) {
        e.preventDefault()
        console.log({
            email_address: this.state.email_address,
            phone_number: this.state.phone_number,
            message: this.state.message,
        })
        let res = await contactFormPost({
            email_address: this.state.email_address,
            phone_number: this.state.phone_number,
            message: this.state.message,
        })
        if (res['statusCode'] === 200) {
            alert('Yhteydenotto lähetettiin onnistuneesti')
        } else {
            alert('Jotain meni vikaan')
        }
        return await res
    }

    render() {
        return (
            <>
                <div className={'App-title'}>
                    <p className={'App-title-text'}>Lähetä viesti niin olemme teihin yhteydessä!</p>
                </div>
                <div className={'App-form-wrapper'}>
                    <h1 className={'App-form-h1'}>Lähetä viesti</h1>
                    <form>
                        <div className={'form_group'}>
                            <input
                                type={'text'}
                                required={true}
                                defaultValue={this.state.email_address}
                                onChange={(e) => this.updateEmail(e.target.value)}
                            />
                            <span className={'form_highlight'}/>
                            <span className={'form_bar'}/>
                            <label>Sähköposti</label>
                        </div>
                        <div className={'form_group'}>
                            <input
                                type={'text'}
                                required={true}
                                defaultValue={this.state.phone_number}
                                onChange={(e) => this.updatePhone(e.target.value)}
                            />
                            <span className={'form_highlight'}/>
                            <span className={'form_bar'}/>
                            <label>Puhelinnumero</label>
                        </div>
                        <div className={'form_group'}>
                            <textarea
                                rows={8}
                                required={true}
                                defaultValue={this.state.message}
                                onChange={(e) => this.updateMessage(e.target.value)}
                            />
                            <span className={'form_highlight'}/>
                            <span className={'form_bar'}/>
                            <label>Viesti</label>
                        </div>
                        <button
                            className={'form_button'}
                            onClick={(e) => this.sendMessage(e)}
                        >Lähetä</button>
                    </form>
                </div>
            </>
        );
    }
}
